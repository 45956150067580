/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Text, Box, Radio, Label, Flex } from '@theme-ui/components'
import { usePostContext } from '../../utils/post-context'
import { tippySx } from '../../utils/tippy'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { useState } from 'react'
import Audio from '../audio-player'
import Markdown from '../markdown'

const RadioMultipleChoiceItem = ({ exercise, id, index, isAudio }) => {
  const { text, options } = exercise

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1)
  const { theme } = useThemeUI()

  return (
    <li>
      {!isAudio && <Text sx={{ mb: 1 }}>{text}</Text>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isAudio ? ['column', 'column', 'row'] : 'row',
          '& > :not(:last-child)': isAudio ? { mb: [2, 2, 0], mr: [0, 0, 2] } : { mr: 2 },
        }}
      >
        {options.map((option, optionIndex) => {
          const isSelected = selectedOptionIndex === optionIndex
          const isHovered = hoveredItemIndex === optionIndex
          let fillProperty = {}
          if (isSelected) {
            fillProperty.fill = option.correct ? 'success' : 'danger'
          }
          return (
            <Box
              sx={{
                flex: '1 1 0px',
                ...tippySx(option.correct ? theme.bulmaRgb.success : theme.bulmaRgb.danger),
              }}
              key={optionIndex}
            >
              <Tippy
                content={<Markdown isInline={false}>{option.tooltip || ''}</Markdown>}
                enabled={!!option.tooltip && isSelected}
                visible={isSelected && isHovered}
                interactive={true}
                arrow={true}
              >
                <Box
                  onMouseEnter={() => setHoveredItemIndex(optionIndex)}
                  onMouseLeave={() => setHoveredItemIndex(-1)}
                  sx={{ display: 'inline-block', width: '100%' }}
                >
                  <Label
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Radio
                      sx={{ '& path': { ...fillProperty } }}
                      onChange={event => {
                        if (event.currentTarget.checked) {
                          setSelectedOptionIndex(optionIndex)
                        }
                      }}
                      name={`${id}-${index}`}
                    />
                    {isAudio ? (
                      <Box
                        sx={{
                          backgroundColor: 'green',
                          width: '100%',
                          '.rhap_horizontal-reverse .rhap_controls-section': {
                            flex: '0 1 auto',
                          },
                          '.rhap_current-time': {
                            mr: 1,
                          },
                        }}
                      >
                        <Audio
                          sxx={{ mb: 0 }}
                          src={option.audio}
                          showSkipControls={false}
                          showJumpControls={false}
                          customAdditionalControls={[]}
                          customVolumeControls={[]}
                          layout="horizontal-reverse"
                        />
                      </Box>
                    ) : (
                      <Text sx={{ width: '100%', display: 'inline-block' }}>{option.text}</Text>
                    )}
                  </Label>
                </Box>
              </Tippy>
            </Box>
          )
        })}
      </Box>
    </li>
  )
}

const RadioMultipleChoice = ({ src, id, type = 'text' }) => {
  const { exercises } = usePostContext().exercises[src]

  return (
    <Box>
      <ol sx={{ pl: 3, 'li:not(:last-child)': { mb: 3 } }}>
        {exercises.map((exercise, index) => {
          return (
            <RadioMultipleChoiceItem
              key={index}
              id={id}
              index={index}
              exercise={exercise}
              isAudio={type === 'audio'}
            />
          )
        })}
      </ol>
    </Box>
  )
}

export default RadioMultipleChoice
