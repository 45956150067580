/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Box } from '@theme-ui/components'
import { CorrectedText } from './transcription'
import { useRef } from 'react'
import { useState } from 'react'
import { IconContext } from 'react-icons'
import { Fragment } from 'react'
import { MdSubdirectoryArrowRight } from 'react-icons/md'

export default function DifferentialFillInTheBlanksField({ answer, placeholder }) {
  const ref = useRef(null)
  const [text, setText] = useState('')
  const [shouldShowDiff, setShouldShowDiff] = useState(false)
  const { theme } = useThemeUI()

  return (
    <Box
      sx={{
        display: 'inline-flex',
        px: '4px',
        mx: '4px',
        pb: '0px',
        borderBottom: ({ colors }) => `2px solid ${colors.text}`,
        alignItems: 'center',
        '& svg': {
          ml: '4px',
        },
        cursor: 'text',
      }}
    >
      {shouldShowDiff ? (
        <CorrectedText fontSize="1.0rem" answer={answer} userText={text} />
      ) : (
        <Fragment>
          <span
            onKeyPress={event => {
              if (event.key === 'Enter') {
                setShouldShowDiff(true)
              }
            }}
            ref={ref}
            contentEditable={!shouldShowDiff}
            onInput={event => setText(event.currentTarget.innerText)}
            sx={{
              minWidth: !placeholder && (text === null || text === '') ? '64px' : '0px',
              outline: 'none',
              cursor: 'text',
              display: 'block',
              ':after': {
                content: 'none',
              },
              ':before': {
                content: 'none',
              },
              '&:empty:after': {
                content: `"${placeholder || ' '}"`,
                whiteSpace: 'pre',
                color: 'lightgrey',
              },

              ':focus': {
                '&:empty:after': {
                  content: `"${placeholder || ' '}"`,
                  whiteSpace: 'pre',
                },
              },
            }}
          />
          <IconContext.Provider value={{ color: theme.colors.info }}>
            <Box onClick={() => setShouldShowDiff(true)} sx={{ cursor: 'pointer' }}>
              <MdSubdirectoryArrowRight />
            </Box>
          </IconContext.Provider>
        </Fragment>
      )}
    </Box>
  )
}
