/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Flex, Box } from '@theme-ui/components'

export default function FlexList({ children, ordered = true, sxx }) {
  return (
    <Flex sx={{ flexDirection: 'column', ...sxx }}>
      {React.Children.map(children, (child, index) => {
        return (
          <Flex key={index} sx={{ flexDirection: 'row' }}>
            <Box
              sx={{
                width: '18px',
                mt: 1,
                mr: 1,
                textAlign: 'right',

                // this is a hack to make the text overflow to the left rather than the right
                direction: 'rtl',
              }}
            >
              {/* note that the period comes before the number due to `direction: 'rtl'` */}
              {`.${index + 1}`}
            </Box>
            {child}
          </Flex>
        )
      })}
    </Flex>
  )
}
