/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Flex, Box, Button } from '@theme-ui/components'
import { usePostContext } from '../../utils/post-context'
import Audio from '../audio-player'
import { Fragment, useRef, useState } from 'react'
import { diffWords } from 'diff'
import FlexList from '../flex-list'
import { IconContext } from 'react-icons'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

export default function Transcription({ src }) {
  const { exercises } = usePostContext().exercises[src]
  const { staticFiles } = usePostContext()

  return (
    <FlexList sxx={{ '& > :not(:last-child)': { mb: 4 } }}>
      {exercises.map((exercise, index) => {
        return <TranscriptionExercise exercise={exercise} staticFiles={staticFiles} key={index} />
      })}
    </FlexList>
  )
}

function TranscriptionExercise({ exercise, staticFiles }) {
  const { audio, answers } = exercise
  const audioPublicUrl = staticFiles[audio].publicURL
  const [inputText, setInputText] = useState('')
  const inputRef = useRef()
  const [showAnswer, setShowAnswer] = useState(false)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        '& > :not(:last-child)': {
          mb: 2,
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          alignItems: ['flex-start', 'center'],
          '& > :not(:last-child)': {
            mr: [0, 3],
            mb: [1, 0],
          },
        }}
      >
        <Flex sx={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            sx={{
              mr: [2, 3],
              '.rhap_container': {
                padding: 0,
                boxShadow: 'none',
                '.rhap_controls-section': { mr: 0, button: { m: 0 } },
              },
            }}
          >
            <Audio
              sxx={{ mb: 0 }}
              src={audio}
              showSkipControls={false}
              showJumpControls={false}
              customAdditionalControls={[]}
              customVolumeControls={[]}
              layout="horizontal-reverse"
              customProgressBarSection={[]}
            />
          </Box>
          <input
            ref={inputRef}
            disabled={showAnswer}
            sx={{
              height: 'fit-content',
              border: 'none',
              borderBottom: ({ colors }) => `2px solid ${colors.text}`,
              borderRadius: '0px',
              fontSize: '1rem',
              flexGrow: '1',
              pb: '3px',
              outline: 'none',
              type: 'text',
              '::placeholder': {
                color: 'lightgrey',
              },
              ':disabled': {
                color: 'grey',
                borderBottom: '2px solid grey !important',
              },
              mr: [2, 0],
            }}
            onChange={event => {
              setInputText(event.target.value)
            }}
            placeholder="Enter transcription"
          ></input>
        </Flex>
        <Button
          disabled={showAnswer}
          onClick={() => setShowAnswer(true)}
          sx={{
            flex: '0 0 auto',
            py: 1,
            px: 2,
            backgroundColor: 'info',
            ':disabled': {
              backgroundColor: 'lightgrey',
            },
            // alignSelf: ['flex-end', 'flex-start'],
          }}
        >
          Check answer
        </Button>
      </Flex>
      <Box sx={{ display: showAnswer ? 'block' : 'none' }}>
        <CorrectedText answer={answers[0]} userText={inputText} />
      </Box>
    </Flex>
  )
}

export function CorrectedText({ answer, userText, fontSize = '1.0rem', shouldShowIcon = true }) {
  const diff = diffWords(userText, answer, {
    ignoreCase: true,
  })
  const { theme } = useThemeUI()
  const isTotallyCorrect = diff.find(part => part.added || part.removed) === undefined

  return (
    <Fragment>
      {diff.map((part, index) => {
        const color = part.added ? 'success' : part.removed ? 'danger' : 'text'
        return (
          <span
            key={index}
            sx={{
              fontSize,
              color,
              textDecoration: color === 'danger' ? 'line-through' : 'none',
              whiteSpace: 'pre',
              ':not(:last-child)': {
                mr: 1,
              },
            }}
          >
            {part.value.trim()}
          </span>
        )
      })}
      {shouldShowIcon && (
        <IconContext.Provider
          value={{ color: isTotallyCorrect ? theme.colors.success : theme.colors.danger }}
        >
          {isTotallyCorrect ? <FaCheckCircle /> : <FaTimesCircle />}
        </IconContext.Provider>
      )}
    </Fragment>
  )
}
