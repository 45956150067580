/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { Fragment } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Header from './header'
import SEO from './seo'
import '../components/layout.css'
import { PostContext } from '../utils/post-context'
import { Flex, Box, Text } from '@theme-ui/components'
import Footer from '../components/footer'

// shortcodes
import TabbedPost from './tabbed-post'
import MdxWithTableOfContents from './mdx-with-table-of-contents'
import RadioMultipleChoice from './exercises/radio-multiple-choice'
import PostMdxRenderer from './post-mdx-renderer'
import InlineMultipleChoice from './exercises/inline-multiple-choice'
import FillInTheBlanks from './exercises/fill-in-the-blanks'
import Matching from './exercises/matching'
import Message from './message'
import EnglishSpanishExample, { EnglishExample, SpanishExample } from './english-spanish-example'
import TextWithTooltip from './text-with-tooltip'
import Audio from './audio-player'
import Image from './image'
import EmailSubscribeForm from './email-subscribe-form'
import IntraPostLink from './intra-post-link'
import Link from './link'
import Transcription from './exercises/transcription'

const shortcodes = {
  TabbedPost,
  MdxWithTableOfContents,
  RadioMultipleChoice,
  Mdx: PostMdxRenderer,
  InlineMultipleChoice,
  FillInTheBlanks,
  Transcription,
  Matching,
  Example: EnglishSpanishExample,
  Spanish: SpanishExample,
  English: EnglishExample,
  Message,
  Tooltip: TextWithTooltip,
  Audio,
  Image,
  Text,
  IntraPostLink,
  Link,
}

const PostLayout = ({ data: { allMdx, allExerciseYaml, allFile }, ...props }) => {
  const mdx = allMdx.nodes.find(node => node.fields && node.fields.fileName.base === 'index.mdx')
  const otherPagesList = allMdx.nodes.filter(node => {
    return node.fields && node.fields.fileName && node.fields.fileName.base !== 'index.mdx'
  })

  const otherPagesMap = {}
  otherPagesList.forEach(otherPage => (otherPagesMap[otherPage.fields.fileName.base] = otherPage))

  const exerciseYaml = allExerciseYaml.nodes
  const exercises = {}
  exerciseYaml.forEach(exercise => (exercises[exercise.fileName] = JSON.parse(exercise.data)))

  const staticFilesMap = {}
  allFile.nodes.forEach(node => {
    const postNameLength = mdx.fields.postName.length
    const pathRelativeToPost = node.relativePath.substring(postNameLength + 1)
    staticFilesMap[pathRelativeToPost] = node
  })

  const postBasePath = `/posts/${mdx.fields.postName}`

  const websiteUrl = 'https://www.getfluentspanish.com'

  return (
    <Fragment>
      {/* todo: add keyword support in frontmatter */}
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.subtitle}
        keywords={['spanish']}
      />
      <Header
        title={mdx.frontmatter.title}
        subtitle={mdx.frontmatter.subtitle}
        includeShareButtons={true}
        shareUrl={`${websiteUrl}${postBasePath}`}
        date={mdx.frontmatter.date}
      />
      <PostContext.Provider
        value={{
          otherPages: otherPagesMap,
          exercises,
          basePath: postBasePath,
          staticFiles: staticFilesMap,
          location: props.location,
        }}
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer otherPages={otherPagesMap} exercises={exercises}>
            {mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </PostContext.Provider>
      <Flex sx={{ justifyContent: 'center', width: '100%', mb: 3 }}>
        <Box sx={{ width: ['100%', null, '960px'], mx: [2, 3, 3] }}>
          <Flex sx={{ justifyContent: 'flex-start', width: '100%', mt: 5 }}>
            {/* on desktop, line up with the table of contents since 100% width is pretty excessive */}
            <Box sx={{ width: ['100%', '100%', '75%'] }}>
              <EmailSubscribeForm />
            </Box>
          </Flex>

          <Footer />
        </Box>
      </Flex>
    </Fragment>
  )
}

export const pageQuery = graphql`
  query PostQuery($postName: String) {
    allMdx(filter: { fields: { postName: { eq: $postName } } }) {
      nodes {
        id
        body
        frontmatter {
          title
          subtitle
          date
          tags
          image {
            publicURL
          }
        }
        fields {
          fileName {
            base
          }
          postName
        }
        tableOfContents
      }
    }
    allExerciseYaml(filter: { postName: { eq: $postName } }) {
      nodes {
        id
        data
        fileName
        postName
      }
    }
    allFile(filter: { fields: { postName: { eq: $postName } } }) {
      nodes {
        publicURL
        relativePath
        base
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
        fields {
          postName
        }
      }
    }
  }
`

export default PostLayout
