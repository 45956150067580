// TODO: make a context based solution for tooltips to make them mutually exclusive
export const tippySx = (rgbArray, textColour) => {
  const tooltipBackground = `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, 0.9)`
  const textColourObject = !!textColour ? { color: textColour } : {}
  return {
    '.tippy-tooltip': {
      backgroundColor: tooltipBackground,
      ...textColourObject,
    },
    '.tippy-arrow': {
      borderTopColor: `${tooltipBackground} !important`,
    },
  }
}
