/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from '@theme-ui/components'
import ReactAudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { usePostContext } from '../utils/post-context'

export default function AudioPlayer({ src, sxx, ...rest }) {
  const publicUrl = usePostContext().staticFiles[src].publicURL

  return (
    <Box sx={{ mb: 4, ...sxx }}>
      <ReactAudioPlayer src={publicUrl} {...rest} />
    </Box>
  )
}
