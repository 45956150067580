import React from 'react'
import TabbedLayout from './tabbed-layout'
import { usePostContext } from '../utils/post-context'

const TabbedPost = ({ children }) => {
  const postContext = usePostContext()
  return (
    <TabbedLayout basePath={postContext.basePath} location={postContext.location}>
      {children}
    </TabbedLayout>
  )
}

export default TabbedPost
