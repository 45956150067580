/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ to, children }) => {
  return (
    <GatsbyLink to={to} style={{ textDecoration: 'none' }}>
      {children}
    </GatsbyLink>
  )
}

export default Link
