/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { Fragment } from 'react'
import { stripTrailingSlash } from '../utils/utils'
import { Router, Link } from '@reach/router'
import GithubSlugger from 'github-slugger'
import { Box } from '@theme-ui/components'
import { IoMdSchool, IoMdHelpCircle, IoIosList } from 'react-icons/io'
import { FaPodcast } from 'react-icons/fa'

const tabLabelsToIcons = {
  Lesson: IoMdSchool,
  Exercises: IoMdHelpCircle,
  Podcast: FaPodcast,
  Examples: IoIosList,
}

const Tab = ({ isActive, url, label }) => {
  const Icon = tabLabelsToIcons[label]
  return (
    <Box
      as="li"
      sx={{
        backgroundColor: isActive ? 'background' : 'transparent',
        mb: 0,
        textAlign: 'center',
        py: 2,
        width: ['100px', '150px', '150px'],
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      }}
    >
      <Link
        sx={{
          color: isActive ? 'primary' : 'background',
          textDecoration: 'none',
          fontSize: [2, 3, 3],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        to={url}
      >
        {Icon && <Icon sx={{ mr: 1 }} />}
        {label}
      </Link>
    </Box>
  )
}

const TabbedLayout = ({ children, basePath, location }) => {
  return (
    <Fragment>
      <div sx={{ backgroundColor: 'primary', display: 'flex', justifyContent: 'center' }}>
        <div sx={{ width: ['100%', '100%', '960px'], mx: [2, 3, 3] }}>
          <ul sx={{ display: 'flex', listStyle: 'none', p: 0, my: 0, ml: 0 }}>
            {children &&
              React.Children.map(children, (child, index) => {
                const slugger = new GithubSlugger()
                const slug = slugger.slug(child.props.label)
                const linkTo = basePath + '/' + slug
                const isActive =
                  stripTrailingSlash(location.pathname) === linkTo ||
                  (!!child.props.default && stripTrailingSlash(location.pathname) === basePath)
                const { label } = child.props
                return (
                  <Tab isActive={isActive} label={label} url={linkTo} key={`${label}${index}`} />
                )
              })}
          </ul>
        </div>
      </div>
      <div sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <div
          sx={{
            width: ['100%', '100%', '960px'],
            px: [2, 3, 0],
          }}
        >
          <Router basepath={basePath}>
            {React.Children.map(children, (child, index) => {
              const slugger = new GithubSlugger()
              const path = child.props.path || slugger.slug(child.props.label)
              return React.cloneElement(child, { ...child.props, path })
            })}
          </Router>
        </div>
      </div>
    </Fragment>
  )
}

export default TabbedLayout
