/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelect } from 'downshift'
import { Button, Text, Flex } from '@theme-ui/components'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { IconContext } from 'react-icons'

const SelectMenu = ({
  isOpen,
  selectedItem,
  items,
  highlightedIndex,
  getMenuProps,
  getItemProps,
}) => {
  return (
    isOpen && (
      <ul
        sx={{
          width: 'auto',
          whiteSpace: 'nowrap',
          position: 'absolute',
          mt: '5px',
          mb: '0px',
          mx: '0px',
          py: '5px',
          px: '0px',
          outline: 'none',
          listStyleType: 'none',
          boxShadow: '0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)',
          borderRadius: '4px',
          zIndex: 999,
          backgroundColor: 'background',
        }}
        {...getMenuProps()}
      >
        {items.map((option, index) => {
          const isHighlighted = highlightedIndex === index
          const isSelected = selectedItem === option
          let backgroundColor
          let color
          if (isSelected) {
            color = 'background'
            backgroundColor = 'hsl(217, 71%,  53%)'
          } else {
            color = 'text'
            if (isHighlighted) {
              backgroundColor = '#eeeeee'
            } else {
              backgroundColor = 'background'
            }
          }
          return (
            <li
              sx={{
                backgroundColor,
                color,
                px: '10px',
                py: '5px',
              }}
              key={`${option}${index}`}
              {...getItemProps({ item: option, index })}
            >
              <Text>{option.text}</Text>
            </li>
          )
        })}
      </ul>
    )
  )
}

const Select = props => {
  const {
    items,
    placeholderText,
    IconForSelectedItem,
    onSelectedItemChange = () => {},
    onIsOpenChange = () => {},
  } = props
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    itemToString: item => item && item.text,
    onSelectedItemChange,
    onIsOpenChange,
  })

  const selectMenuProps = {
    selectedItem,
    getMenuProps,
    getItemProps,
    isOpen,
    items,
    highlightedIndex,
  }

  const hasSelectedItem = selectedItem && selectedItem.text

  return (
    <div sx={{ display: 'inline-block', position: 'relative', fontSize: 1 }}>
      <Button
        {...getToggleButtonProps()}
        sx={{
          backgroundColor: 'white',
          color: 'text',
          borderBottom: ({ colors }) => `2px solid ${colors.text}`,
          borderRadius: '0px',
          py: '2px',
          mx: '5px',
          px: '4px',
          outline: 'none',
          lineHeight: '1',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Text sx={{ color: hasSelectedItem ? 'text' : 'grey', fontSize: 2 }}>
            {(selectedItem && selectedItem.text) || placeholderText}
          </Text>
          {IconForSelectedItem && (
            <span sx={{ ml: '4px' }}>
              <IconForSelectedItem />
            </span>
          )}
          <IconContext.Provider value={{ size: '18px' }}>
            <MdKeyboardArrowDown
              sx={{
                display: 'inline',

                // the hardcoded padding for this SVG is a bit weird so we correct it
                mt: '2px',
                mb: '-2px',
                mr: '-4px',
              }}
            />
          </IconContext.Provider>
        </Flex>
      </Button>
      <SelectMenu {...selectMenuProps} />
    </div>
  )
}

export default Select
