import { useState, useRef } from 'react'
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Box, Text, Button, Flex } from '@theme-ui/components'
import { usePostContext } from '../../utils/post-context'
import { IoMdHelpCircle } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { removeAccents, markdown } from '../../utils/utils'
import { tippySx } from '../../utils/tippy'
import Message from '../message'
import Tippy from '@tippy.js/react'
import ModalPopup from '../modal-popup'
import Markdown from '../markdown'
import DifferentialFillInTheBlanksField from './differential-fill-in-the-blanks'

export default function FillInTheBlanks({
  id,
  src,
  presentation = 'list',
  correctionType = 'hint',
}) {
  const { exercises } = usePostContext().exercises[src]
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [exerciseForHintModal, setExerciseForHintModal] = useState(null)
  const [exerciseIndexForHintModal, setExerciseIndexForHintModal] = useState(0)

  const exerciseComponents = exercises.map((exercise, index) => {
    return (
      <FillInTheBlanksExercise
        correctionType={correctionType}
        exercise={exercise}
        key={index}
        openModal={(exercise, index) => {
          setExerciseForHintModal(exercise)
          setExerciseIndexForHintModal(index)
          setIsModalOpen(true)
        }}
      />
    )
  })

  const wrappedExerciseComponents = exerciseComponents.map((exerciseComponent, index) => {
    if (presentation === 'list') {
      return <li key={index}>{exerciseComponent}</li>
    } else {
      return <p key={index}>{exerciseComponent}</p>
    }
  })

  return (
    <Box>
      {isModalOpen && exerciseForHintModal && (
        <HintModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          exercise={exerciseForHintModal}
          fieldIndex={exerciseIndexForHintModal}
        />
      )}

      {presentation === 'list' ? (
        <ol sx={{ 'li:not(:last-child)': { mb: 3 }, pl: 3 }}>{wrappedExerciseComponents}</ol>
      ) : (
        wrappedExerciseComponents
      )}
    </Box>
  )
}

function FillInTheBlanksExercise({ exercise, openModal, correctionType }) {
  return exercise.map((item, index) => {
    if (typeof item === 'string') {
      return (
        <Text
          sx={{ display: 'inline' }}
          key={index}
          dangerouslySetInnerHTML={{ __html: markdown.renderInline(item) }}
        ></Text>
      )
    } else if (correctionType === 'differential') {
      return <DifferentialFillInTheBlanksField key={index} {...item} />
    } else {
      return (
        <FillInTheBlanksField key={index} openModal={() => openModal(exercise, index)} {...item} />
      )
    }
  })
}

export function FillInTheBlanksField({ answers, tooltips, placeholder, openModal }) {
  const [text, setText] = useState(null)
  const ref = useRef(null)
  const giveFocus = event => {
    ref.current.focus()
  }

  const { theme } = useThemeUI()

  const { state, tooltip } = fillInTheBlanksState(text, answers, tooltips)

  const RedX = () => (
    <IconContext.Provider value={{ color: theme.colors.danger }}>
      <FaTimesCircle />
    </IconContext.Provider>
  )

  const YellowX = () => (
    <IconContext.Provider value={{ color: theme.colors.warning }}>
      <FaTimesCircle />
    </IconContext.Provider>
  )

  const GreyX = () => (
    <IconContext.Provider value={{ color: 'lightgrey' }}>
      <FaTimesCircle />
    </IconContext.Provider>
  )

  const BlueQuestionMark = () => (
    <Box
      as="span"
      sx={{ cursor: 'pointer', width: '19px', height: '19px', mr: '3px' }}
      onClick={() => {
        console.log('onclick')
        openModal()
      }}
    >
      <IconContext.Provider value={{ color: theme.colors.info }}>
        <IoMdHelpCircle size="19px" />
      </IconContext.Provider>
    </Box>
  )

  const GreenCheckmark = () => (
    <IconContext.Provider value={{ color: theme.colors.success }}>
      <FaCheckCircle />
    </IconContext.Provider>
  )

  const tooltipColour = {
    correct: theme.bulmaRgb.success,
    wrong: theme.bulmaRgb.danger,
    warning: theme.bulmaRgb.warning,
  }[state]

  const tooltipTextColour = state === 'warning' ? theme.colors.warningTooltipText : undefined
  const tippySxProp = tooltipColour ? tippySx(tooltipColour, tooltipTextColour) : {}

  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  // TODO: disable newlines (probably easiest to just intercept enter presses and ignore them)
  return (
    <Box as="span" sx={tippySxProp}>
      <Tippy
        interactive={true}
        visible={isFocused || isHovered}
        content={tooltip}
        enabled={!!tooltip}
        arrow={true}
      >
        <Box
          sx={{
            display: 'inline-flex',
            px: '4px',
            mx: '4px',
            pb: '0px',
            borderBottom: ({ colors }) => `2px solid ${colors.text}`,
            alignItems: 'center',
            '& svg': {
              ml: '4px',
            },
            cursor: 'text',
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={giveFocus}
        >
          <span
            ref={ref}
            contentEditable="true"
            onInput={event => setText(event.currentTarget.innerText)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false)
            }}
            sx={{
              minWidth: !placeholder && (text === null || text === '') ? '64px' : '0px',
              outline: 'none',
              cursor: 'text',
              display: 'block',
              pointerEvents: isFocused ? 'auto' : 'none',
              ':after': {
                content: 'none',
              },
              ':before': {
                content: 'none',
              },
              '&:empty:after': {
                content: `"${placeholder || ' '}"`,
                whiteSpace: 'pre',
                color: 'lightgrey',
              },

              ':focus': {
                '&:empty:after': {
                  content: `"${placeholder || ' '}"`,
                  whiteSpace: 'pre',
                },
              },
            }}
          />

          <span>
            {/** for some reason this span is necessary to make the cursor stay in the right position */}
          </span>
          {state === 'correct' && <GreenCheckmark />}
          {state === 'wrong' && <RedX />}
          {state === 'warning' && <YellowX />}
          {state === 'incomplete' && <GreyX />}
          {state !== 'correct' && <BlueQuestionMark />}
        </Box>
      </Tippy>
    </Box>
  )
}

function fillInTheBlanksState(text, answers, tooltips) {
  if (!text || text === '') {
    return { state: 'empty' }
  }

  const trimmedLowerCaseText = (text || '').trim().toLowerCase()
  const trimmedLowerCaseAnswers = new Set(answers.map(answer => answer.trim().toLowerCase()))
  if (trimmedLowerCaseAnswers.has(trimmedLowerCaseText)) {
    return { state: 'correct' }
  }

  const trimmedLowerCaseTooltipsMap = {}
  const tooltipsWithoutAccentsMap = {}
  ;(tooltips || []).forEach(tooltip => {
    tooltip.responses.forEach(response => {
      trimmedLowerCaseTooltipsMap[response.trim().toLowerCase()] = tooltip
      tooltipsWithoutAccentsMap[removeAccents(response.trim().toLowerCase())] = tooltip
    })
  })

  let currentTooltip = trimmedLowerCaseTooltipsMap[trimmedLowerCaseText]
  if (currentTooltip) {
    return {
      state: currentTooltip.warning ? 'warning' : 'wrong',
      tooltip: currentTooltip.text,
    }
  }

  const textWithoutAccents = removeAccents(trimmedLowerCaseText)
  const answersWithoutAccents = new Set(
    [...trimmedLowerCaseAnswers].map(answer => removeAccents(answer))
  )
  if (answersWithoutAccents.has(textWithoutAccents)) {
    return {
      state: 'warning',
      tooltip: 'So close! Just be careful with the accents.',
    }
  }

  currentTooltip = tooltipsWithoutAccentsMap[textWithoutAccents]
  if (currentTooltip) {
    return {
      state: currentTooltip.warning ? 'warning' : 'wrong',
      tooltip: currentTooltip.text,
    }
  }

  return { state: 'incomplete' }
}

function HintModal({ exercise, isOpen, setIsOpen, fieldIndex }) {
  const exerciseText = exercise
    .map(item => {
      if (typeof item === 'string') {
        return item
      } else {
        return '__________'
      }
    })
    .join('  ')

  // TODO: support for multiple fields
  const [isAnswerShown, setIsAnswerShown] = useState(false)
  const { answers, hint } = exercise[fieldIndex]

  return (
    <ModalPopup isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex sx={{ flexDirection: 'column', '& > *': { p: 2 } }}>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'whitesmoke',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            borderBottom: ({ colors }) => `1px solid ${colors.lightgrey}`,
            fontSize: 3,
          }}
        >
          Hint
        </Box>
        <Box sx={{ flexGrow: '1', backgroundColor: 'white' }}>
          <Message>
            <Markdown sxx={{ whiteSpace: 'pre-wrap' }}>{exerciseText}</Markdown>
          </Message>
          <Text sx={{ mt: 3, mb: 3, whiteSpace: 'pre-wrap' }}>{hint}</Text>
          {isAnswerShown && (
            <Box sx={{ mt: 3 }}>
              <Text sx={{ fontWeight: 'bold' }}>Answer</Text>
              <ul>
                {answers.map(answer => (
                  <li key={answer}>{answer}</li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'whitesmoke',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            borderTop: ({ colors }) => `1px solid ${colors.lightgrey}`,
          }}
        >
          <Button sx={{ mr: 2, backgroundColor: 'info' }} onClick={() => setIsAnswerShown(true)}>
            Show answer
          </Button>
          <Button
            sx={{ backgroundColor: 'background', color: 'text' }}
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </Box>
      </Flex>
    </ModalPopup>
  )
}
