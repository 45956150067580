import random from 'random'
import seedrandom from 'seedrandom'
import MarkdownIt from 'markdown-it'

export const markdown = new MarkdownIt()

export const stripTrailingSlash = url => {
  if (url.endsWith('/')) {
    return url.substring(0, url.length - 1)
  } else {
    return url
  }
}

export const removeAccents = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const areStringsRoughlyEqual = (s1, s2) => {
  const s1Processed = s1.trim().toLowerCase()
  const s2Processed = s2.trim().toLowerCase()
  return s1Processed.localeCompare(s2Processed, 'en', { sensitivity: 'base' }) === 0
}

export const hashCode = s => {
  let hash = 0
  if (s.length === 0) {
    return hash
  }
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

/**
 * Returns a 2-element array, the first element of which is a shuffled copy of `a`,
 * and the second of which is an array containing the order of the indices of `a`
 * in the shuffled copy.
 *
 * Although the order is random, the same array `a` will always have the same shuffle
 * order (hence "consistent").
 * @param {Array} a
 */
export const consistentShuffle = a => {
  const rng = random.clone(seedrandom(JSON.stringify(a)))
  const availableIndices = [...Array(a.length).keys()]
  const shuffledIndices = Array(a.length)

  for (let i = 0; i < a.length; i++) {
    const randomIndex = rng.int(0, availableIndices.length - 1)
    shuffledIndices[i] = availableIndices[randomIndex]
    availableIndices.splice(randomIndex, 1)
  }

  return [a.map((_, index) => a[shuffledIndices[index]]), shuffledIndices]
}
