import React from 'react'
import { usePostContext } from '../utils/post-context'
import MdxWithTableOfContents from './mdx-with-table-of-contents'

const PostMdxRenderer = ({ src }) => {
  const postContext = usePostContext()
  return <MdxWithTableOfContents {...postContext} mdx={postContext.otherPages[src]} />
}

export default PostMdxRenderer
