/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Fragment } from 'react'
import { Text } from '@theme-ui/components'

const TableOfContents = ({ items, level = 1 }) => {
  if (!items) {
    return null
  }

  if (level === 1) {
    return (
      <Fragment>
        <Text sx={{ textTransform: 'uppercase', color: 'grey', fontSize: 1 }}>
          {items[0].title}
        </Text>
        <TableOfContents level={level + 1} items={items[0].items} />
      </Fragment>
    )
  } else {
    return (
      <ul
        sx={{
          pl: 2,
          ul: {
            borderLeft: '1px solid lightgrey',
            ml: 2,
            fontSize: 1,
          },
        }}
      >
        {items.map((item, index) => {
          return (
            <Fragment key={`${item.title}${index}`}>
              <li
                sx={{
                  listStyle: 'none',
                  ':hover': {
                    backgroundColor: 'whitesmoke',
                  },
                  p: 1,
                  mb: 1,
                }}
              >
                <a href={item.url} sx={{ textDecoration: 'none', color: 'text' }}>
                  {item.title}
                </a>
              </li>
              {item.items && <TableOfContents level={level + 1} items={item.items} />}
            </Fragment>
          )
        })}
      </ul>
    )
  }
}

const MdxWithTableOfContents = props => {
  const { body, tableOfContents } = props.mdx

  return (
    <div sx={{ display: 'flex', width: '100%' }}>
      <div
        sx={{
          width: ['100%', '75%', '75%'],
          height: 'auto',
        }}
      >
        <MDXRenderer {...props}>{body}</MDXRenderer>
      </div>
      <div
        sx={{
          width: '25%',
          position: 'sticky',
          top: '16px',
          display: ['none', 'block', 'block'],
          height: '100%',
          ml: 3,
        }}
      >
        <TableOfContents items={tableOfContents.items} />
      </div>
    </div>
  )
}

export default MdxWithTableOfContents
