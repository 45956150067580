/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Card } from '@theme-ui/components'
import { Fragment } from 'react'

const ModalPopup = ({ isOpen, setIsOpen, children }) => {
  return (
    <Fragment>
      <Box
        sx={{
          visibility: isOpen ? 'visible' : 'hidden',
          position: 'fixed',
          left: '0',
          top: '0',
          px: '20px',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          display: 'flex',
          opacity: isOpen ? '1.0' : '0.0',
          transitionProperty: 'opacity',
          transitionDuration: '200ms',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setIsOpen(false)}
      >
        <Card
          sx={{
            p: 0,
            maxWidth: '640px',
          }}
          onClick={event => {
            event.stopPropagation()
          }}
        >
          {children}
        </Card>
      </Box>
    </Fragment>
  )
}

export default ModalPopup
