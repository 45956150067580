/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { usePostContext } from '../utils/post-context'

const IntraPostLink = ({ to, children }) => {
  const { basePath } = usePostContext()
  return (
    <Link style={{ textDecoration: 'none' }} to={`${basePath}/${to}`}>
      {children}
    </Link>
  )
}

export default IntraPostLink
