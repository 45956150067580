/** @jsx jsx */
import { jsx } from 'theme-ui'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'

const TextWithTooltip = ({ tooltip, children }) => {
  return (
    <Tippy content={tooltip}>
      <span sx={{ color: 'link' }}>{children}</span>
    </Tippy>
  )
}

export default TextWithTooltip
