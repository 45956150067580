/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Text } from '@theme-ui/components'
import Markdown from './markdown'

export const EnglishExample = ({ children }) => {
  return (
    <Box>
      <Markdown sxx={{ opacity: '0.5' }}>{children}</Markdown>
    </Box>
  )
}

export const SpanishExample = ({ children }) => {
  return (
    <Box>
      <Markdown>{children}</Markdown>
    </Box>
  )
}

const EnglishSpanishExample = ({ sx, children }) => {
  return <Box sx={sx}>{children}</Box>
}

export default EnglishSpanishExample
