/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { usePostContext } from '../utils/post-context'
import GatsbyImage from 'gatsby-image'

const Image = ({ src, alt, sxx = {} }) => {
  const { theme } = useThemeUI()
  const fluidImage = usePostContext().staticFiles[src].childImageSharp.fluid
  return <GatsbyImage style={{ marginBottom: theme.space[3], ...sxx }} fluid={fluidImage} />
}

export default Image
