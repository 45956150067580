/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Text } from '@theme-ui/components'
import { markdown } from '../utils/utils'

export default function Markdown({ isInline = true, sxx, children }) {
  return (
    <Text
      sx={{
        display: isInline ? 'inline' : 'block',
        'p:first-of-type': {
          mt: 0,
        },
        'p:last-of-type': {
          mb: 0,
        },
        ...sxx,
      }}
      dangerouslySetInnerHTML={{
        __html: isInline ? markdown.renderInline(children) : markdown.render(children),
      }}
    ></Text>
  )
}
