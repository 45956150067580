/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { useState } from 'react'
import Select from '../select'
import { usePostContext } from '../../utils/post-context'
import { tippySx } from '../../utils/tippy'
import { Text, Box } from '@theme-ui/components'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { Fragment } from 'react'
import { markdown } from '../../utils/utils'

const InlineMultipleChoiceField = ({ options, presentation = 'list' }) => {
  const [selectedItem, setSelectedItem] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const { theme } = useThemeUI()

  const RedX = () => (
    <IconContext.Provider value={{ color: theme.colors.danger }}>
      <FaTimesCircle />
    </IconContext.Provider>
  )
  const GreenCheckmark = () => (
    <IconContext.Provider value={{ color: theme.colors.success }}>
      <FaCheckCircle />
    </IconContext.Provider>
  )

  let IconForSelectedItem
  if (selectedItem && selectedItem.correct) {
    IconForSelectedItem = GreenCheckmark
  } else if (selectedItem && !selectedItem.correct) {
    IconForSelectedItem = RedX
  } else {
    IconForSelectedItem = null
  }

  return (
    <Box as="span" sx={tippySx(theme.bulmaRgb.danger)}>
      <Tippy
        content={(selectedItem && selectedItem.tooltip) || ''}
        interactive={true}
        enabled={selectedItem && !!selectedItem.tooltip && !isMenuOpen}
        visible={!!selectedItem && isHovered}
        arrow={true}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <Select
            onSelectedItemChange={item => setSelectedItem(item.selectedItem)}
            IconForSelectedItem={IconForSelectedItem}
            placeholderText="select"
            items={options}
            onIsOpenChange={isOpen => setIsMenuOpen(isOpen.isOpen)}
          />
        </span>
      </Tippy>
    </Box>
  )
}

const InlineMultipleChoiceExercise = ({ exercise }) => {
  return (
    <Fragment>
      {exercise.map((item, itemIndex) => {
        if (typeof item === 'string') {
          return (
            <Text
              sx={{ display: 'inline' }}
              key={itemIndex}
              dangerouslySetInnerHTML={{ __html: markdown.renderInline(item) }}
            ></Text>
          )
        } else {
          return <InlineMultipleChoiceField key={itemIndex} options={item} />
        }
      })}
    </Fragment>
  )
}
const InlineMultipleChoice = ({ src, id, presentation = 'list' }) => {
  const { exercises } = usePostContext().exercises[src]
  if (presentation === 'list') {
    return (
      <ol>
        {exercises.map((exercise, exerciseIndex) => {
          return (
            <li key={exerciseIndex} sx={{ ':not(:last-child)': { mb: 2 } }}>
              <InlineMultipleChoiceExercise key={exerciseIndex} exercise={exercise} />
            </li>
          )
        })}
      </ol>
    )
  } else if (presentation === 'paragraphs') {
    return (
      <Fragment>
        {exercises.map((exercise, exerciseIndex) => {
          return (
            <div sx={{ my: '16px' }} key={exerciseIndex}>
              <InlineMultipleChoiceExercise key={exerciseIndex} exercise={exercise} />
            </div>
          )
        })}
      </Fragment>
    )
  }
}

export default InlineMultipleChoice
