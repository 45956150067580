/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useDrag, useDrop } from 'react-dnd'
import { Text, Box, Flex } from '@theme-ui/components'
import { usePostContext } from '../../utils/post-context'
import { useState } from 'react'
import { Fragment } from 'react'
import { Preview } from 'react-dnd-multi-backend'
import { consistentShuffle } from '../../utils/utils'

const moveItem = (sourceList, sourceIndex, targetList, targetIndex) => {
  const newSourceList = [...sourceList]
  const newTargetList = [...targetList]
  const itemFromSourceList = sourceList[sourceIndex]
  const itemFromTargetList = targetList[targetIndex]

  newSourceList[sourceIndex] = itemFromTargetList
  newTargetList[targetIndex] = itemFromSourceList

  return [newSourceList, newTargetList]
}

const LeftColumnItemContainer = ({ onDrop, sxx, children }) => {
  const [, drop] = useDrop({
    accept: 'option-item',
    drop: droppedItem => {
      onDrop(droppedItem)
    },
  })
  return (
    <Box ref={drop} sx={{ ...sxx }}>
      {children}
    </Box>
  )
}

const optionItemSx = color => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: color,
  color: 'background',
  height: '100%',
  borderRadius: '6px',
  p: ['2px', '6px', '10px'],
})

const OptionItem = ({ text, dndItemData, color }) => {
  const [{ isDragging }, drag] = useDrag({
    item: dndItemData,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <Box className="matching-option-item" ref={drag} sx={optionItemSx(color)}>
      {text}
    </Box>
  )
}

const RightColumnItemContainer = ({ children, sxx, onDrop }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'option-item',
    drop: droppedItem => {
      onDrop(droppedItem)
    },
  })
  return (
    <Box ref={drop} sx={{ borderRadius: '6px', backgroundColor: 'whitesmoke', ...sxx }}>
      {children}
    </Box>
  )
}

const RightColumnLabel = ({ children, sxx }) => {
  return (
    <Text
      sx={{ mt: ['10px', '20px', null], mb: ['5px', '5px', null], textAlign: 'center', ...sxx }}
    >
      {children}
    </Text>
  )
}

// TODO: generate ID from file name instead of having author specify it
const Matching = ({ id, src }) => {
  const { pairs, leftColumnTitle, rightColumnTitle } = usePostContext().exercises[src]
  const [shuffledPairs, shuffleMapping] = consistentShuffle(pairs)
  const [state, setState] = useState({
    left: shuffledPairs.map(pair => pair[0]),
    right: Array(pairs.length).fill(null),
  })

  const onDrop = (targetColumn, targetIndex, droppedItem) => {
    if (targetColumn === droppedItem.column) {
      // swap within same column
      const updatedColumn = [...state[targetColumn]]
      updatedColumn[targetIndex] = state[targetColumn][droppedItem.index]
      updatedColumn[droppedItem.index] = state[targetColumn][targetIndex]

      setState({
        left: targetColumn === 'left' ? updatedColumn : state.left,
        right: targetColumn === 'right' ? updatedColumn : state.right,
      })
    } else {
      // swap between columns
      const [newSourceColumn, newTargetColumn] = moveItem(
        state[droppedItem.column],
        droppedItem.index,
        state[targetColumn],
        targetIndex
      )

      setState({
        left: targetColumn === 'left' ? newTargetColumn : newSourceColumn,
        right: targetColumn === 'right' ? newTargetColumn : newSourceColumn,
      })
    }
  }

  const previewGenerator = (type, item, style) => {
    const { offsetWidth: width, offsetHeight: height } = document.querySelector(
      '.matching-option-item'
    )
    const text = state[item.column][item.index]
    return (
      <div style={{ ...style, width, height }} sx={optionItemSx('info')}>
        {text}
      </div>
    )
  }

  return (
    <Fragment>
      <Box id={id}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Flex sx={{ flexDirection: 'row' }}>
            <Text sx={{ width: '50%', textAlign: 'center' }}>{leftColumnTitle}</Text>
            <Text sx={{ width: '50%', textAlign: 'center' }}>{rightColumnTitle}</Text>
          </Flex>
          <Box
            sx={{
              fontSize: 0,
              display: 'grid',
              gridColumnGap: ['5px', '10px', null],
              gridRowGap: '0px',
              gridAutoFlow: 'row',
              gridTemplateColumns: ['1fr 1fr 5px 1fr 1fr', '1fr 1fr 10px 1fr 1fr', null],
              gridAutoRows: '1fr',
            }}
          >
            <Preview generator={previewGenerator} />
            {state.left.map((item, index) => {
              return (
                <LeftColumnItemContainer
                  onDrop={droppedItem => onDrop('left', index, droppedItem)}
                  sxx={{
                    gridColumnStart: `${1 + (index % 2)}`,
                    gridColumnEnd: 'span 1',
                    gridRowStart: `${2 * (Math.floor(index / 2) + 1)}`,
                    gridRowEnd: 'span 1',
                  }}
                  key={`${item}${index}`}
                >
                  {item && (
                    <OptionItem
                      color="info"
                      dndItemData={{
                        type: 'option-item',
                        column: 'left',
                        index: index,
                      }}
                      text={item}
                    />
                  )}
                </LeftColumnItemContainer>
              )
            })}
            {state.right.map((item, index) => (
              <Fragment key={`${item}${index}`}>
                <RightColumnLabel
                  sxx={{
                    gridColumnStart: `${4 + (index % 2)}`,
                    gridColumnEnd: 'span 1',
                    gridRowStart: `${2 * Math.floor(index / 2) + 1}`,
                    gridRowEnd: 'span 1',
                    alignSelf: 'end',
                  }}
                >
                  {pairs[index][1]}
                </RightColumnLabel>
                <RightColumnItemContainer
                  onDrop={droppedItem => onDrop('right', index, droppedItem)}
                  sxx={{
                    gridColumnStart: `${4 + (index % 2)}`,
                    gridColumnEnd: 'span 1',
                    gridRowStart: `${2 * Math.floor(index / 2) + 2}`,
                    gridRowEnd: 'span 1',
                  }}
                >
                  {item && (
                    <OptionItem
                      color={item === pairs[index][0] ? 'success' : 'danger'}
                      dndItemData={{
                        type: 'option-item',
                        column: 'right',
                        index,
                      }}
                      text={item}
                    />
                  )}
                </RightColumnItemContainer>
              </Fragment>
            ))}
          </Box>
        </Flex>
      </Box>
    </Fragment>
  )
}

export default Matching
