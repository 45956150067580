/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { Box } from '@theme-ui/components'

const Message = ({ children, sx = {}, color = 'text' }) => {
  const {
    theme: { colors },
  } = useThemeUI()

  let foregroundColor, backgroundColor

  switch (color) {
    case 'success':
      foregroundColor = colors.successForeground
      backgroundColor = colors.successBackground
      break
    case 'info':
      foregroundColor = colors.infoForeground
      backgroundColor = colors.infoBackground
      break
    case 'link':
      foregroundColor = colors.linkForeground
      backgroundColor = colors.linkBackground
      break
    case 'danger':
      foregroundColor = colors.dangerForeground
      backgroundColor = colors.dangerBackground
      break
    case 'warning':
      foregroundColor = colors.warningForeground
      backgroundColor = colors.warningBackground
      break
    default:
      foregroundColor = 'text'
      backgroundColor = 'whitesmoke'
      break
  }

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        color: `${foregroundColor} !important`,
        '*': {
          color: `${foregroundColor}`,
        },
        border: 'none',
        borderLeftColor: color,
        borderLeftStyle: 'solid',
        borderLeftWidth: '4px',
        borderRadius: '4px',
        py: 3,
        px: 3,
        my: 2,
        ...sx,
        '& > :last-child': {
          mb: 0,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default Message
